/**
 * Image
 */
/* global isJestTest */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import classNames from 'classnames';
import { useStyle } from 'Themes/theme';

import sD from './Image.Dansskolanentre.module.scss';
import sK from './Image.Kulturama.module.scss';
import sM from './Image.Medborgarskolan.module.scss';
import sP from './Image.Pahlmans.module.scss';
import sT from './Image.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Image = ({
    src = '',
    alt = '',
    width = null,
    height = null,
    focal = {},
    srcset = [],
    sizes = [],
    className = '',
    asNext = false,
    useCover = false,
    lazyLoad = true,
    priority = false,
    onLoad = undefined,
}) => {
    const s = useStyle(styles);

    const [isLoaded, setIsLoaded] = useState(false);
    const [useObjectFit, setUseObjectFit] = useState(true);

    useEffect(() => {
        // Fallback for object fit, e.g. ie11
        const styles = document.documentElement.style;
        const isTest = typeof isJestTest !== 'undefined' && isJestTest;
        if(!isTest && 'objectFit' in styles === false) {
            setIsLoaded(true);
            setUseObjectFit(false);
        }
    }, []);

    const loadHandler = () => {
        setIsLoaded(true);

        if(onLoad) {
            onLoad();
        }
    };

    let style = {};
    let position = '';
    if(focal.x && focal.y) {
        position = `${focal.x} ${focal.y}`;
    }

    const classes = classNames(
        className,
        s['Image'],
        {[s['Image--Loaded']]: isLoaded},
        {[s['Image--Cover']]: useCover},
        {[s['Image--Contain']]: !useCover},
        {[s['Image--Fallback']]: !useObjectFit},
    );

    if(!useObjectFit) {
        style = {
            backgroundSize: useCover ? 'cover' : 'contain',
            backgroundImage: `url('${src}')`,
        };
        if(position) {
            style.backgroundPosition = position;
        }

        return (
            <div className={classes} style={style} />
        );
    }

    if(position) {
        style.objectPosition = position;
    }

    if(asNext) {
        return (
            <NextImage
                className={classes}
                src={src}
                alt={alt}
                width={useCover ? undefined : width}
                height={useCover ? undefined : height}
                srcSet={srcset.join(', ')}
                sizes={sizes.join(', ')}
                fill={useCover}
                style={style}
                onLoad={loadHandler}
                priority={priority}
                loading={lazyLoad ? 'lazy' : 'eager'}
            />
        );
    }

    return (
        <img
            className={classes}
            src={src}
            alt={alt}
            width={width}
            height={height}
            srcSet={srcset.join(', ')}
            sizes={sizes.join(', ')}
            style={style}
            onLoad={() => loadHandler()}
            loading={lazyLoad ? 'lazy' : undefined}
        />
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    focal: PropTypes.object,
    srcset: PropTypes.array,
    sizes: PropTypes.array,
    className: PropTypes.string,
    asNext: PropTypes.bool,
    useCover: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    priority: PropTypes.bool,
    onLoad: PropTypes.func,
};

export default Image;

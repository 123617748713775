/**
 * Wysiwyg
 */

import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import parse from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';
import { isCookieAllowed } from 'utils/Cookie';
import BaseContext from 'Layouts/BasePage/BaseContext';
import VideoMessage from 'Components/Video/VideoMessage';
import { useStyle } from 'Themes/theme';

import sD from './Wysiwyg.Dansskolanentre.module.scss';
import sK from './Wysiwyg.Kulturama.module.scss';
import sM from './Wysiwyg.Medborgarskolan.module.scss';
import sP from './Wysiwyg.Pahlmans.module.scss';
import sT from './Wysiwyg.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Wysiwyg = ({wysiwyg, modifier}) => {
    const {cookiePopup, isSSR} = useContext(BaseContext);

    const s = useStyle(styles);

    const defaultConsented = isCookieAllowed(cookiePopup, 'YSC');
    const [consented, setConsented] = useState(defaultConsented);

    const [loadYoutube, setLoadYoutube] = useState(false);

    const classes = classNames(
        s['Wysiwyg'],
        {[s[`Wysiwyg--${modifier}`]]: modifier},
        'Wysiwyg',
    );

    useEffect(() => {
        document.addEventListener('cookie-popup-changed', () => {
            setConsented(isCookieAllowed(cookiePopup, 'YSC'));
        });
    }, []);

    useEffect(() => {
        if(!isSSR && !loadYoutube) {
            setLoadYoutube(true);
        }
    }, [isSSR, loadYoutube]);

    // replaceAll seems to be missing in SSR, make sure it is added
    if(typeof String.prototype.replaceAll === 'undefined') {
        String.prototype.replaceAll = function(match, replace) {
            return this.replace(new RegExp(match, 'g'), () => replace);
        }
    }

    const sanitizedWysiwyg = SanitizeHTML(wysiwyg, {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            '*': function(tagName, attribs) {
                if(attribs?.style && !_.isEmpty(attribs.style)) {
                    const regex = /\b(font-size|font-weight|line-height|font-family|color):[^;]+;/gi;
                    attribs.style = attribs.style.replaceAll(regex, '');
                }
                return {
                    tagName: tagName,
                    attribs: attribs,
                };
            }
        }
    });

    const transform = (reactNode, domNode, index) => {
        const {name, attribs} = domNode;
        if(name === 'iframe') {
            const {src} = attribs;
            if(src.includes('youtube')) {
                const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = src.match(regExp);
                const videoId = match && match[2].length === 11 ? match[2] : '';

                const style = {};
                if(videoId !== '') {
                    style.backgroundImage = `url('https://img.youtube.com/vi/${videoId}/maxresdefault.jpg')`;
                }
                if(!loadYoutube || !consented) {
                    return (
                        <div className={s['Wysiwyg__VideoMessage']} style={style} key={index}>
                            <VideoMessage
                                src={src}
                                iframe={reactNode}
                            />
                        </div>
                    );
                } else {
                    return (
                        <LazyLoad height="100%" once={true} offset={400} key={index}>
                            {reactNode}
                        </LazyLoad>
                    );
                }
            } else if(src.includes('vimeo')) {
                const consented = isCookieAllowed(cookiePopup, 'vuid');
                const iframeSrc = consented ? src : `${src}&dnt=1`;
                attribs.src = iframeSrc;
                return (
                    <LazyLoad height="100%" once={true} offset={400} key={index}>
                        {reactNode}
                    </LazyLoad>
                );
            }
        }
        return (
            <>{reactNode}</>
        );
    };

    const parsedWysiwyg = parse(sanitizedWysiwyg, {
        transform(reactNode, domNode, index) {
            return transform(reactNode, domNode, index);
        },
    });

    return (
        <div className={classes}>
            {parsedWysiwyg}
        </div>
    );
};

Wysiwyg.propTypes = {
    wysiwyg: PropTypes.string,
    modifier: PropTypes.string,
};

Wysiwyg.defaultProps = {
    wysiwyg: '',
    modifier: '',
};

export default Wysiwyg;

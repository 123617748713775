/**
 * DisplayDateTime
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

const DisplayDateTime = ({
    date = '',
}) => {
    const dateRaw = DateSrFormat(date);
    const dateFormatted = DateTextFormat(date);

    if(!date) {
        return null;
    }

    return (
        <time datetime={dateRaw}>{dateFormatted}</time>
    );
};

DisplayDateTime.propTypes = {
    date: PropTypes.string,
};

const DateTextFormat = (date = '') => {
    const { t } = useTranslation();
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    if(isNaN(month)) {
        return '';
    } else {
        const monthText = t(`utils.months.${month}`);
        return `${day} ${monthText} ${year}`;
    }
};

const DateSrFormat = (date = '') => {
    const d = new Date(date);
    const day = d.getDate();
    const dayRaw = day < 10 ? `0${day}` : day;
    const month = d.getMonth();
    const monthRaw = month+1 < 10 ? `0${month+1}` : month+1;
    const year = d.getFullYear();
    return `${year}-${monthRaw}-${dayRaw}`;
};

export default DisplayDateTime;

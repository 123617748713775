/**
 * SlickSlider
 */

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import IconControl from '../../public/inline-svg/chevron.svg';
import { useStyle } from 'Themes/theme';

import sD from './SlickSlider.Dansskolanentre.module.scss';
import sK from './SlickSlider.Kulturama.module.scss';
import sM from './SlickSlider.Medborgarskolan.module.scss';
import sP from './SlickSlider.Pahlmans.module.scss';
import sT from './SlickSlider.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const SlickSlider = ({
    Component = null,
    items = [],
    title = '',
    mobileInset = null,
    size = 'Third',
    modifier = '',
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    // So the slides are Xpx smaller in mobile than viewport
    const showMobileInset = mobileInset !== null;

    const slidesToShow = size === 'Half' ? 2 : 3;
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 767, // sm
                settings: {
                    slidesToShow: 1,
                    centerMode: showMobileInset,
                    centerPadding: mobileInset,
                },
            },
            {
                breakpoint: 1023, // lg
                settings: { slidesToShow: 2 },
            },
        ],
        nextArrow: <Control type="Next" label={t('slickSlider.next')} />,
        prevArrow: <Control type="Prev" label={t('slickSlider.prev')} />,
    };

    const halfSizes = [
        '(min-width: 1380px) 628px',
        '(min-width: 768px) 50vw',
        '100vw',
    ];
    const thirdSizes = [
        '(min-width: 1380px) 414px',
        '(min-width: 1024px) 33vw',
        '(min-width: 768px) 50vw',
        '100vw',
    ];
    const imageSizes = size === 'Half' ? halfSizes : thirdSizes;

    const classes = classNames(
        s['SlickSlider'],
        {[s['SlickSlider--MobileInset']]: showMobileInset},
        {[s[`SlickSlider--${modifier}`]]: modifier},
        {[s[`SlickSlider--${size}`]]: size},
    );
    return (
        <div className={classes}>
            <h2 className={s['SlickSlider__Title']}>{title}</h2>
            <div className={s['SlickSlider__Slider']}>
                <div />{/* Empty div to fix slick slider bug */}
                <Slider {...settings}>
                    {items && Array.isArray(items) && items.map((item, index) => (
                        <div
                            key={index}
                            className={s['SlickSlider__Slide']}
                        >
                            <Component
                                {...item}
                                imageSizes={imageSizes}
                                modifier={`Slider${modifier}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

SlickSlider.propTypes = {
    Component: PropTypes.func,
    title: PropTypes.string,
    items: PropTypes.array,
    mobileInset: PropTypes.string,
    size: PropTypes.string,
    modifier: PropTypes.string,
};

const Control = ({type, label, onClick}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['SlickSlider__Control'],
        s[`SlickSlider__Control--${type}`],
        {[s['SlickSlider__Control--Disabled']]: !onClick},
    );

    return (
        <div
            className={classes}
            onClick={onClick}
        >
            <IconControl />
            <span className="sr-only">{label}</span>
        </div>
    );
};

Control.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
};

Control.defaultProps = {
    type: '',
    label: '',
    onClick: null,
};

export default SlickSlider;

/**
 * SliderCardNews
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import SlickSlider from 'Components/SlickSlider';
import CardNews from 'Components/CardNews';
import Arrow from '../../public/inline-svg/arrow-kulturama-thin.svg';
import { useStyle } from 'Themes/theme';

import sD from './SliderCardNews.Dansskolanentre.module.scss';
import sK from './SliderCardNews.Kulturama.module.scss';
import sM from './SliderCardNews.Medborgarskolan.module.scss';
import sP from './SliderCardNews.Pahlmans.module.scss';
import sT from './SliderCardNews.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const SliderCardNews = ({
    title = '',
    link = null,
    items = [],
    hasBg = false,
    noTopSpacing = false,
    className = '',
}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['SliderCardNews'],
        {[s['SliderCardNews--HasBg']]: hasBg},
        {[s['SliderCardNews--NoTopSpacing']]: noTopSpacing},
        className,
    );
    return (
        <div className={classes}>
            <div className={s['SliderCardNews__Container']}>
                <SlickSlider
                    Component={CardNews}
                    items={items}
                    title={title}
                    mobileInset="50px"
                    modifier="CardNews"
                />

                {!_.isEmpty(link) &&
                    <div className={s['SliderCardNews__Button']}>
                        <Link {...link} />
                    </div>
                }
            </div>
        </div>
    );
};

const Link = ({
    title = null,
    text = '',
    href = '',
    target = null,
    rel = null,
}) => {
    const s = useStyle(styles);

    return (
        <a
            className={s['SliderCardNews__Link']}
            href={href}
            title={title}
            target={target}
            rel={rel}
        ><Arrow /><span>{text}</span></a>
    );
};

Link.propTypes = {
    text: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
};

SliderCardNews.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    link: PropTypes.object,
    hasBg: PropTypes.bool,
    noTopSpacing: PropTypes.bool,
};

export default SliderCardNews;

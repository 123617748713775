/**
 * CardNews
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Image from 'Components/Image';
import DisplayDateTime from 'Components/DisplayDateTime';
import IconPlay from '../../public/inline-svg/play.svg';
import IconArrow from '../../public/inline-svg/arrow-pahlmansGy.svg';
import { useStyle } from 'Themes/theme';

import sD from './CardNews.Dansskolanentre.module.scss';
import sK from './CardNews.Kulturama.module.scss';
import sM from './CardNews.Medborgarskolan.module.scss';
import sP from './CardNews.Pahlmans.module.scss';
import sT from './CardNews.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const CardNews = ({
    published = '',
    authorName = '',
    topic = '',
    category = '',
    educationLevel = '',
    title = '',
    text = '',
    image = {},
    isVideo = false,
    link = {},
    imageSizes = [],
}) => {
    const { t } = useTranslation();

    const s = useStyle(styles);

    const classes = classNames(
        s['CardNews'],
        {[s['CardNews--Video']]: isVideo},
    );

    const truncateText =
        text.length > 160 ? text.substring(0, 160) + '...' : text;

    return (
        <article className={classes}>
            <div className={s['CardNews__Container']}>
                {image && image.src && (
                    <div className={s['CardNews__ImageContainer']}>
                        <Image
                            {...image}
                            className={s['CardNews__Image']}
                            sizes={imageSizes}
                            useCover={true}
                        />
                        {isVideo && <IconPlay />}
                    </div>
                )}
                <div className={s['CardNews__Content']}>
                    {_.isEmpty(category) && !_.isEmpty(topic) && (
                        <div className={s['CardNews__Category']}>{topic}</div>
                    )}
                    {!_.isEmpty(category) && (
                        <div className={s['CardNews__Category']}>{category}</div>
                    )}
                    <h3 className={s['CardNews__Title']}>{title}</h3>
                    <p className={s['CardNews__Text']}>{truncateText}</p>
                    <div className={s['CardNews__Meta']}>
                        {educationLevel && (
                            <div className={s['CardNews__Type']}>
                                {educationLevel}
                            </div>
                        )}
                        <div className={s['CardNews__Date']}>
                            <span className="sr-only">{t('cardNews.published')}: </span>
                            <DisplayDateTime date={published} />
                        </div>
                        {authorName && (
                            <div className={s['CardNews__Author']}>{authorName}</div>
                        )}
                    </div>
                </div>
            </div>

            <IconArrow />

            <a className={s['CardNews__Link']} href={link.href}>
                <span
                    className="sr-only"
                    dangerouslySetInnerHTML={{ __html: link.text }}
                />
            </a>
        </article>
    );
};

CardNews.propTypes = {
    published: PropTypes.string,
    topic: PropTypes.string,
    category: PropTypes.string,
    isVideo: PropTypes.bool,
    image: PropTypes.object,
    title: PropTypes.string,
    authorName: PropTypes.string,
    text: PropTypes.string,
    educationLevel: PropTypes.string,
    link: PropTypes.object,
    imageSizes: PropTypes.array,
};

export default CardNews;

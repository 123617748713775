/**
 * VideoMessage
 */

import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useStyle } from 'Themes/theme';

import sD from './VideoMessage.Dansskolanentre.module.scss';
import sK from './VideoMessage.Kulturama.module.scss';
import sM from './VideoMessage.Medborgarskolan.module.scss';
import sP from './VideoMessage.Pahlmans.module.scss';
import sT from './VideoMessage.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const VideoMessage = ({src, iframe, showVideo}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const [isVideoVisible, setIsVideoVisible] = useState(false);

    useEffect(() => {
        if(isVideoVisible) {
            showVideo();
        }
    }, [isVideoVisible]);

    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = src.match(regExp);
    const videoId = match && match[2].length === 11 ? match[2] : '';
    const url = `https://www.youtube.com/watch?v=${videoId}`;

    const style = {};
    if(videoId !== '') {
        style.backgroundImage = `url('https://img.youtube.com/vi/${videoId}/maxresdefault.jpg')`;
    }

    return (
        <div className={s['VideoMessage']}>
            {isVideoVisible ? (
                <Fragment>{iframe}</Fragment>
            ) : (
                <div className={s['VideoMessage__Content']}>
                    <p
                        className={s['VideoMessage__Text']}
                        dangerouslySetInnerHTML={{__html: t('video.videoMessage.msg')}}
                    />

                    <button
                        className={s['VideoMessage__Button']}
                        onClick={() => setIsVideoVisible(true)}
                        type="button"
                    ><span>{t('video.videoMessage.showVideo')}</span></button>

                    <a className={s['VideoMessage__Link']} href={url}>
                        {t('video.videoMessage.goToVideo')}
                    </a>
                </div>
            )}
        </div>
    );
};

VideoMessage.propTypes = {
    src: PropTypes.string,
    iframe: PropTypes.object,
    showVideo: PropTypes.func,
};

VideoMessage.defaultProps = {
    src: '',
    iframe: null,
    showVideo: () => {},
};

export default VideoMessage;

/**
 * Breadcrumbs
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import Breadcrumb from '../../public/inline-svg/breadcrumb.svg';
import { useStyle } from 'Themes/theme';

import sD from './Breadcrumbs.Dansskolanentre.module.scss';
import sK from './Breadcrumbs.Kulturama.module.scss';
import sM from './Breadcrumbs.Medborgarskolan.module.scss';
import sP from './Breadcrumbs.Pahlmans.module.scss';
import sT from './Breadcrumbs.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Breadcrumbs = ({title, items, modifier, componentClass}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['Breadcrumbs'],
        {[s[`Breadcrumbs--${modifier}`]]: modifier},
        componentClass,
    );

    return (
        <nav className={classes} aria-label={title}>
            {items.map((item, index)  =>
                <Item key={index} {...item} />
            )}
        </nav>
    );
};

Breadcrumbs.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    modifier: PropTypes.string,
    componentClass: PropTypes.string,
};

Breadcrumbs.defaultProps = {
    title: '',
    items: [],
    modifier: '',
    componentClass: '',
};

const Item = ({title, link, isSelf, isRoot}) => {
    const {t} = useTranslation();
    const s = useStyle(styles);

    const sanitizedTitle = SanitizeHTML(title);

    return (
        <div className={s['Breadcrumbs__Item']}>
            {isSelf ? (
                <span
                    className={s['Breadcrumbs__Page']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            ) : (
                <a
                    className={s['Breadcrumbs__Link']}
                    href={link}
                    aria-label={isRoot ? t('breadcrumbs.rootLabel') : undefined}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            )}

            <span className={s['Breadcrumbs__Separator']}>
                <Breadcrumb />
            </span>
        </div>
    );
};

Item.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    isSelf: PropTypes.bool,
    isRoot: PropTypes.bool,
};

Item.defaultProps = {
    title: '',
    link: '',
    isSelf: false,
    isRoot: false,
};

export default Breadcrumbs;
